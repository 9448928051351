<template>
  <v-container>
    <v-icon @click="$emit('cancel')" class="close">fas fa-times</v-icon>
    <v-row>
      <v-col class="preview-container" cols="12" sm="12" md="12">
        <img
          :lazy-src="lazy"
          v-if="showImage"
          class="media-control"
          :src="url"
        />
        <video
          controls
          v-if="showVideo"
          class="media-control"
          :src="url"
        ></video>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <div v-if="source.length > 1" class="cards-scroll">
          <v-tooltip bottom v-for="(item, index) of source" :key="index">
            <template v-slot:activator="{ on }">
              <img
                v-on="on"
                class="thumbnail"
                @click="playFile(item)"
                :src="
                  item.url ||
                  'https://firebasestorage.googleapis.com/v0/b/beanage-data/o/defaultImage.svg?alt=media&token=fbada6c0-836b-45f2-bbc9-2931f7e1aa12'
                "
                height="70px"
                width="70px"
                :style="
                  selectedFile == item.url
                    ? 'border-bottom: 5px solid #008BBF; opacity: 0.5;'
                    : ''
                "
              />
            </template>
            <span>Clic para ver el contenido.</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "view-playlist",
  props: ["files"],
  data() {
    return {
      source: [],
      showVideo: false,
      showImage: false,
      url: "",
      selectedFile: "",
      lazy: "",
      videoFormats: ["mp4", "avi", "mkv"],
      imageFormats: ["png", "jpeg", "jpg", "gif", "svg"],
    };
  },

  methods: {
    playFile(item) {
      let file = item ? item : this.source[0];
      this.selectedFile = file.url;
      this.url = file.url;
      this.lazy = file.screenshot ? file.screenshot : "";

      if (this.videoFormats.includes(file.format)) {
        this.showVideo = true;
        this.showImage = false;
      } else {
        this.showVideo = false;
        this.showImage = true;
      }
    },
  },
  async mounted() {
    this.source = this.files.map((item) => {
      let format = item.split("?")[0].split(".").pop();
      return {
        url: item,
        format,
      };
    });

    this.playFile();
  },
};
</script>

<style scoped>
.cards-scroll img {
  border-radius: 5px;
  margin: 10px;
}
.close {
  position: absolute;
  top: 40px;
  right: 40px;
  color: white;
  font-size: 30px !important;
  font-weight: bold;
  cursor: pointer;
}
.cards-scroll {
  display: flex;
  overflow-x: auto;
  min-width: 100%;
  justify-content: center;
  height: 20vh;
  max-height: 20vh;
  align-items: center;
}
.media-control {
  max-height: 70vh;
  max-width: 90%;
  object-fit: contain;
}

.preview-container {
  height: 80vh;
  max-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail {
  cursor: pointer;
  object-fit: fill;
}

@media only screen and (max-width: 600px) {
  .media-control {
    min-width: 90%;
    max-width: 90%;
    max-height: 70vh;
  }
  .cards-scroll img {
    width: 90px;
    height: 90px;
  }
}
</style>