<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid class="fill-height">
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $t("products.title") }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn color="buttons" @click="showAddItemDialog" :loading="loading">
            <v-icon left> fas fa-plus </v-icon>
            {{ $t("products.add") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="products"
            :showEdit="true"
            :showDelete="true"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="addItemDialog"
      persistent
      max-width="900px"
      v-model="addItemDialog"
    >
      <add-product
        :originalItem="selectedItem"
        @success="itemAdded"
        @cancel="addItemDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="deleteVideoDialog"
      v-model="deleteVideoDialog"
    >
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteVideoDialog = false"
      />
    </v-dialog>

    <v-dialog
      fullscreen
      hide-overlay
      v-if="viewImagesDialog"
      v-model="viewImagesDialog"
    >
      <view-images :files="images" @cancel="viewImagesDialog = false" />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import addProduct from "./add-product";
import deleteItem from "@/components/delete";
import filesTable from "@/components/files-table";
import viewImages from "@/components/view-files-list";
import { mapActions, mapState } from "vuex";

export default {
  name: "Products",
  components: {
    addProduct,
    deleteItem,
    filesTable,
    viewImages,
  },
  data() {
    return {
      products: [],
      snackbarText: "",
      snackbar: false,
      viewImagesDialog: false,
      images: [],
      headers: [
        {
          value: "productImage",
          width: "150px",
        },
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.description"),
          value: "description",
          width: "150px",
        },
        {
          text: this.$t("headers.price"),
          value: "price",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          width: "200px",
          align: "end",
        },
      ],
      loading: true,
      addItemDialog: false,
      selectedItem: null,
      deleteVideoDialog: false,
    };
  },

  computed: {
    ...mapState(["groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    showAddItemDialog() {
      this.selectedItem = null;
      this.addItemDialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.addItemDialog = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "show_image":
          this.images = payload.item.images.map((item) => {
            return item.url;
          });
          if (this.images.length) this.viewImagesDialog = true;
          break;
        case "edit":
          this.editItem(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
      }
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteVideoDialog = true;
    },
    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("products")
        .doc(this.selectedItem[".key"])
        .delete()
        .then(async (response) => {
          this.snackbarText = this.$t("products.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteVideoDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },

    itemAdded() {
      this.addItemDialog = false;
      this.snackbarText = this.selectedItem
        ? this.$t("products.updated")
        : this.$t("products.added");
      this.snackbar = true;
    },
  },
  mounted() {
    this.cleanSearch();
    db.collection("products")
      .where("groupId", "==", this.groupId)
      .onSnapshot((response) => {
        this.products = [];
        response.forEach((item) => {
          let product = item.data();
          product[".key"] = item.id;
          this.products.push(product);
        });
        this.loading = false;
      });
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>