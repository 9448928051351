<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ originalItem ? $t("dialog.edit") : $t("dialog.add") }}
          {{ $t("products.short_title") }}</span
        >
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col class="mt-3" cols="12" sm="6" md="6">
              <div class="image-container">
                <img
                  id="image"
                  :style="
                    !getImages.length
                      ? 'border:1px solid #52525242; min-width: 100%; min-height: 235px; max-height: 235px; object-fit:cover;filter: grayscale(100%);'
                      : ''
                  "
                  contain
                  :src="currentFile"
                  class="preview"
                />
                <div style="width: 100%">
                  <i @click="filePicker" class="fas fa-plus add"></i>
                </div>
              </div>

              <div class="thumbnails">
                <div
                  v-for="(item, index) of getImages"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-icon
                    @click="deleteImage(item)"
                    class="delete-image"
                    style="font-size: 13px !important"
                    >fas fa-times</v-icon
                  >
                  <v-img
                    @click="showImage(item)"
                    :style="
                      currentFile == item
                        ? 'border-bottom: 5px solid #008BBF; opacity: 0.5;'
                        : ''
                    "
                    class="product-image"
                    :src="item"
                  ></v-img>
                </div>
                <div
                  v-for="(item, index) of 3 - getImages.length"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-img
                    @click="filePicker"
                    style="
                      object-fit: cover;
                      filter: grayscale(100%);
                      margin-top: 30px;
                      border: 1px solid #52525242;
                    "
                    class="product-image"
                    :src="defaultImage"
                  ></v-img>
                </div>
              </div>
              <v-progress-linear
                :value="progress"
                color="progress-bar"
                class="mt-6"
                style="border-radius: 5px"
                height="30"
              >
                <strong
                  >{{ `${uploadedImages}/${getImages.length}` }} ({{
                    Math.ceil(progress)
                  }}%)</strong
                >
              </v-progress-linear>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="product.name"
                    :label="$t('dialog.name')"
                    :loading="loading"
                    clearable
                    hide-details
                    rounded
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model.number="product.price"
                    :label="$t('dialog.price')"
                    hide-details
                    :loading="loading"
                    rounded
                    filled
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="product.subtitle"
                    :label="$t('dialog.subtitle')"
                    hide-details
                    :loading="loading"
                    rounded
                    filled
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    :value="product.description"
                    :label="$t('dialog.description')"
                    :loading="loading"
                    clearable
                    hide-details
                    v-model="product.description"
                    :rows="6"
                    rounded
                    filled
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span>{{ $t("products.product_characteristics") }}</span>
                  <v-select
                    :items="characteristics"
                    v-if="!originalItem"
                    item-text="name"
                    item-value=".key"
                    :loading="loading"
                    @change="updateItems"
                    :label="$t('products.select_feature_set')"
                    rounded
                    class="mt-3"
                    filled
                  ></v-select>

                  <draggable
                    v-bind="dragOptions"
                    v-model="product.characteristics"
                  >
                    <transition-group type="transition">
                      <!-- <div v-for="element in myArray" :key="element.id">
                        {{ element.name }}
                      </div> -->
                      <v-row
                        style="cursor: move"
                        v-for="(item, index) of product.characteristics"
                        :key="index"
                      >
                        <v-col cols="5" sm="5" md="5">
                          <v-text-field
                            v-model="item.name"
                            :label="$t('dialog.name')"
                            :loading="loading"
                            clearable
                            rounded
                            filled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model="item.value"
                            :label="$t('dialog.description')"
                            v-on:keyup.enter="addCharacteristic"
                            :loading="loading"
                            clearable
                            rounded
                            filled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          v-if="product.characteristics.length > 1"
                          cols="1"
                          sm="1"
                          md=""
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                @click="removeCharacteristic(index)"
                                v-on="on"
                                style="cursor: pointer"
                                class="mt-5"
                              >
                                fas fa-trash-alt
                              </v-icon>
                            </template>
                            <span>{{ $t("products.remove_feature") }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </transition-group>
                  </draggable>

                  <!-- <v-row> </v-row> -->
                </v-col>
              </v-row>
            </v-col>

            <v-col @click="addCharacteristic" cols="12" sm="12" md="12">
              <span style="cursor: pointer; color: #000079">
                <i
                  style="
                    cursor: pointer;
                    color: #000079 !important;
                    font-size: 20px;
                  "
                  class="fas fa-plus-circle mr-3"
                ></i
                >{{ $t("products.add_feature") }}</span
              >
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="originalItem ? update() : save()"
                  color="buttons"
                  >{{ $t("button.save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-file-input
      accept="image/png, image/jpeg"
      show-size
      v-show="false"
      counter="3"
      hint="Máximo 3 imágenes"
      persistent-hint
      multiple
      :disabled="loading"
      @change="selectedFile"
      id="fileInput"
      label="Seleccionar imágenes"
      style="display: none"
      rounded
      filled
    ></v-file-input>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "add-product",
  props: ["originalItem"],
  components: {
    draggable,
  },
  data() {
    return {
      product: {
        name: "",
        price: "",
        description: "",
        images: [],
        characteristics: [
          { name: "", value: "" },
          { name: "", value: "" },
        ],
      },
      uploadedImages: 0,
      currentFile: require("@/assets/wink-placeholder.png"),
      characteristics: [],
      defaultImage: require("@/assets/wink-placeholder.png"),
      snackbarText: "",
      snackbar: false,
      loading: false,
      files: [],
      progress: 0,
      sourceFiles: [],
    };
  },
  computed: {
    ...mapState(["user", "userDoc", "groupId"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    getImages() {
      if (this.sourceFiles.length > 0)
        return [
          ...this.product.images.map((item) => {
            return item.url;
          }),
          ...this.sourceFiles,
        ];
      else
        return this.product.images.map((item) => {
          return item.url;
        });
    },
  },

  methods: {
    addCharacteristic() {
      this.product.characteristics.push({
        name: "",
        value: "",
      });
    },
    removeCharacteristic(index) {
      this.product.characteristics.splice(index, 1);
    },
    updateItems(id) {
      this.product.characteristics = [];
      let result = this.characteristics.find((item) => item[".key"] == id);
      result.items.forEach((item) => {
        this.product.characteristics.push({
          name: item,
          value: "",
        });
      });
    },
    deleteImage(item) {
      if (item.includes("data:image")) {
        let index = this.sourceFiles.indexOf(item);
        this.files.splice(index, 1);
        this.sourceFiles.splice(index, 1);
      } else {
        this.product.images = this.product.images.filter(
          (image) => image.url != item
        );
      }

      if (!this.getImages.length)
        this.currentFile = require("@/assets/wink-placeholder.png");
      else this.currentFile = this.getImages[0];
    },
    filePicker() {
      document.getElementById("fileInput").click();
    },
    showImage(url) {
      this.currentFile = url;
    },
    selectedFile(e) {
      if (this.product.images.length + this.sourceFiles.length + e.length > 3) {
        this.snackbarText = this.$t("products.max_images");
        this.snackbar = true;
        return;
      } else {
        this.files = this.files.concat(e);
        this.sourceFiles = [];
        for (let i = 0; i < this.files.length; i++) {
          var reader = new FileReader();
          const image = this.files[i];
          reader.onload = (e) => {
            this.src = e.target.result;
            this.sourceFiles.push(e.target.result);

            if (i == 0) this.currentFile = e.target.result;
          };
          reader.readAsDataURL(image);
        }
      }
    },

    async save() {
      if (this.product.name) {
        this.loading = true;
        let images = [];
        this.product.characteristics = this.product.characteristics.filter(
          (item) => item.name && item.value
        );

        db.collection("products")
          .add({
            name: this.product.name,
            description: this.product.description,
            characteristics: this.product.characteristics,
            price: this.product.price,
            createdAt: new Date(),
            createdBy: this.user.uid,
            groupId: this.groupId,
            subtitle: this.product.subtitle ? this.product.subtitle : "",
          })
          .then(async (ref) => {
            for (let i = 0; i < this.files.length; i++) {
              const file = this.files[i];
              this.progress = 0;
              this.uploadedImages = i + 1;
              let url = await this.uploadImages(ref.id, file);
              let filename = `${Date.now()}_${Math.floor(
                Math.random() * (10000000000 - 10000000) + 10000000
              )}_${Math.floor(
                Math.random() * (10000000000 - 10000000) + 10000000
              )}`;
              images.push({
                filename,
                url,
              });
            }
            await db.collection("products").doc(ref.id).update({ images });
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t("products.insert_name");
        this.snackbar = true;
      }
    },

    update() {
      if (this.product.name) {
        this.loading = true;
        let images = [];
        this.product.characteristics = this.product.characteristics.filter(
          (item) => item.name && item.value
        );

        db.collection("products")
          .doc(this.originalItem[".key"])
          .update({
            name: this.product.name,
            description: this.product.description,
            characteristics: this.product.characteristics,
            price: this.product.price,
            modifiedAt: new Date(),
            modifiedBY: this.user.uid,
            subtitle: this.product.subtitle ? this.product.subtitle : "",
          })
          .then(async (response) => {
            for (let i = 0; i < this.files.length; i++) {
              this.uploadedImages = i + 1;
              this.progress = 0;
              const file = this.files[i];
              let url = await this.uploadImages(
                this.originalItem[".key"],
                file
              );
              let filename = `${Date.now()}_${Math.floor(
                Math.random() * (10000000000 - 10000000) + 10000000
              )}_${Math.floor(
                Math.random() * (10000000000 - 10000000) + 10000000
              )}`;

              images.push({
                filename,
                url,
              });
            }

            await db
              .collection("products")
              .doc(this.originalItem[".key"])
              .update({
                images: this.product.images.concat(images),
              });

            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t("products.insert_name");
        this.snackbar = true;
      }
    },

    uploadImages(productId, file) {
      return new Promise((resolve, reject) => {
        let extension = file.type.split("/").pop();
        let dataName = `${Math.round(
          Math.random() * (10000000000000 - 100000000) + 100000000
        )}_${Math.round(
          Math.random() * (10000000000000 - 100000000) + 100000000
        )}.${extension}`;

        var uploadTask = fb
          .app()
          .storage("gs://beanage-products")
          .ref()
          .child(`${this.groupId}/${productId}/${dataName}`)
          .put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    },
  },
  async mounted() {
    await this.$binding(
      "characteristics",
      db.collection("characteristics").where("groupId", "==", this.groupId)
    );

    if (this.originalItem) {
      this.product = Object.assign({}, this.originalItem);
      this.currentFile = this.product.images.length
        ? this.product.images[0].url
        : this.defaultImage;
      this.product.characteristics =
        this.product.characteristics.length == 0
          ? [
              { name: "", value: "" },
              { name: "", value: "" },
            ]
          : this.product.characteristics;
    }
  },
};
</script>

<style>
.product-image {
  cursor: pointer;
  max-width: 100%;
  max-height: 90px;
  min-width: 100%;
  min-height: 90px;
  height: 90px;
  object-fit: fill;
  border: 1px solid black;
}
.add-image {
  width: 50px;
  height: 50px;
  background-color: #f4ede4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.delete-image {
  position: relative !important;
  z-index: 1000;
  color: white !important;
  top: 15px;
  right: -15px;
  font-weight: bold;
  background-color: #6a6a6ac9;
  border-radius: 50px;
  padding: 5px;
  width: 30px;
  height: 30px;
  float: right;
  cursor: pointer;
  font-weight: bold;
}
.add-icon {
  font-size: 20px !important;
  cursor: pointer;
  background-color: rgb(135, 120, 192);
  padding: 20px;
  color: white !important;
  border-radius: 70px;
}

.preview {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.image-container {
  width: 100%;
  max-height: 235px;
  position: relative;
}

.add {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

.thumbnails {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
  width: 100%;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.flip-list-move {
  transition: transform 0.5s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>